class Footer {
  constructor() {}

  init() {
    const btnTop = document.getElementById("backtotop");

    btnTop.addEventListener("click", function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  }
}

export { Footer };
