import { Slick } from "../slick";

class Amenities {
  constructor() {
    this.init();
  }

  init() {
    jQuery('[data-slider="features"]').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      infinite: true,
      arrows: false,
      dots: false,
    });

    jQuery('[data-slider="features"]').on(
      "afterChange",
      function (event, slick, currentSlide, nextSlide) {
        jQuery(".amenities-features__list--item").removeClass(
          "amenities-features__list--item--active"
        );
        jQuery(`[data-id="${nextSlide}"]`).addClass(
          "amenities-features__list--item--active"
        );
      }
    );

    jQuery(".amenities-features__list--item").on("mouseover", function () {
      jQuery('[data-slider="features"]').slick(
        "slickGoTo",
        jQuery(this).data("id")
      );
    });

    AOS.init({
      useClassNames: true,
      offset: 100,
      disable: "mobile",
    });
  }
}

export { Amenities };
